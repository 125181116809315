import { useContext, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { AuthContext } from 'state/auth';
import { routes } from 'utils/routing';

export default function useAuthRedirects() {
  const { loading, isAuthenticated, needsReset } = useContext(AuthContext);
  const authenticated = isAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!needsReset && !authenticated && location.pathname !== routes.signIn) {
      navigate(routes.signIn, { replace: true });
    }
  }, [authenticated, loading, location.pathname, navigate, needsReset]);
}
