import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { AuthFlags } from 'state/auth/types';

import {
  USER_POOL_ID,
  USER_POOL_CLIENT_ID,
  USER_POOL_ENDPOINT,
  OKTA_AUTH_OPTIONS,
} from '../../constants';

export const userPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: USER_POOL_CLIENT_ID,
  endpoint: USER_POOL_ENDPOINT,
});

export const oktaAuthOptions: AuthOptions = Auth.configure(OKTA_AUTH_OPTIONS);

let userToSignIn: CognitoUser | null = null;

////////////////////////////////////////////////////////////
// Sign In
////////////////////////////////////////////////////////////

export const signIn = ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<CognitoUserSession | AuthFlags> => {
  const normalizedUsername = normalizeString(username);
  userToSignIn = getCognitoUserForUsername(normalizedUsername);

  return new Promise((resolve, reject) =>
    userToSignIn?.authenticateUser(
      new AuthenticationDetails({
        Username: normalizedUsername,
        Password: password,
      }),
      {
        onSuccess: (session) => resolve(session),

        onFailure: (error) => {
          console.error('Error signing in: ', error);
          return reject(error);
        },

        newPasswordRequired: (userAttributes) =>
          resolve({ ...userAttributes, shouldChangePassword: true }),

        totpRequired: () => resolve({ totpRequired: true }),

        mfaSetup: () => mfaSetupCallback(resolve, reject),
      },
    ),
  );
};

// https://github.com/amazon-archives/amazon-cognito-identity-js/blob/6b87f1a30a998072b4d98facb49dcaf8780d15b0/src/CognitoUser.js#L1587
// verifies TOTP token when users initially associates their device to Cognito
export const verifyTokenForAssociation = (token: string): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) =>
    userToSignIn?.verifySoftwareToken(token, '', {
      onSuccess: (data) => resolve(data),

      onFailure: (error) => reject(error),
    }),
  );

// https://github.com/amazon-archives/amazon-cognito-identity-js/blob/6b87f1a30a998072b4d98facb49dcaf8780d15b0/src/CognitoUser.js#L667
// sends totp to complete signin after users complete the initial association (verifyTokenForAssociation) with their device
export const verifyTokenForSignIn = (token: string): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) =>
    userToSignIn?.sendMFACode(
      token,
      {
        onSuccess: (data) => resolve(data),

        onFailure: (error) => reject(error),
      },
      // https://github.com/amazon-archives/amazon-cognito-identity-js/blob/6b87f1a30a998072b4d98facb49dcaf8780d15b0/src/CognitoUser.js#L672
      'SOFTWARE_TOKEN_MFA',
    ),
  );

export const completeNewPasswordChallenge = ({ newPassword }: { newPassword: string }) =>
  new Promise((resolve, reject) => {
    userToSignIn?.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (data) => resolve(data),

      onFailure: (error) => {
        console.error('Error changing password: ', error);
        return reject(error);
      },

      mfaSetup: () => mfaSetupCallback(resolve, reject),
    });
  });

////////////////////////////////////////////////////////////
// Sign Out
////////////////////////////////////////////////////////////
// Returns a promise of true if the user is signed out successfully
// false otherwise
export const signOut = (shouldSignOutGlobally = false) => {
  return new Promise<boolean>(async (resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      // If a cognito user doesn't exist, check for an Okta user
      try {
        await Auth.currentAuthenticatedUser();
        await Auth.signOut({ global: shouldSignOutGlobally });
        return resolve(true);
      } catch (error) {
        reject(error);
      }
      // Otherwise, the user doesn't exist, no need to sign out
      return resolve(false);
    }
    //If a global sign out is required
    if (shouldSignOutGlobally) {
      cognitoUser?.globalSignOut({
        onSuccess: (_msg: string) => {
          resolve(true);
        },
        onFailure: (error) => {
          console.error('Error signing out globally: ', error);
          reject(error);
        },
      });
    } else {
      cognitoUser?.signOut(() => {
        resolve(true);
      });
    }
  });
};

////////////////////////////////////////////////////////////
// Reset Password
////////////////////////////////////////////////////////////

export const getResetPasswordCode = ({ username }: { username: string }) =>
  new Promise<void>((resolve, reject) => {
    const currentUser = getCognitoUserForUsername(username);

    currentUser.forgotPassword({
      onSuccess: () => resolve(),
      onFailure: (error) => {
        console.error('Error getting verification code', error);
        return reject(error);
      },
    });
  });

export const resetPassword = ({
  username,
  verificationCode,
  newPassword,
}: {
  username: string;
  verificationCode: string;
  newPassword: string;
}) =>
  new Promise<void>((resolve, reject) => {
    const currentUser = getCognitoUserForUsername(username);

    currentUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => resolve(),
      onFailure: (error) => {
        console.error('Error resetting password', error);
        return reject(error);
      },
    });
  });

////////////////////////////////////////////////////////////
// Helpers
////////////////////////////////////////////////////////////

const getCognitoUserForUsername = (username: string) => {
  const normalizedUsername = normalizeString(username);
  return new CognitoUser({ Username: normalizedUsername, Pool: userPool });
};

const mfaSetupCallback = (resolve: any, reject: any) => {
  // https://github.com/amazon-archives/amazon-cognito-identity-js/blob/6b87f1a30a998072b4d98facb49dcaf8780d15b0/src/CognitoUser.js#L1557
  // returns an authenticator-compatible OTP url
  userToSignIn?.associateSoftwareToken({
    associateSecretCode: (totpSecret) =>
      resolve({
        totpSecret,
      }),

    onFailure: (error) => {
      console.error('Error with associating software token: ', error);
      return reject(error);
    },
  });
};

const normalizeString = (str = '') => str.trim().toLowerCase();
