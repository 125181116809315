import { FC, useCallback, useState, useMemo } from 'react';

import { useQuery } from '@apollo/react-hooks';
import {
  Drawer,
  AppBar,
  Badge,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Warning,
  ExpandLess,
  ExpandMore,
  Language,
  Home,
  Note,
  Today,
} from '@material-ui/icons';
import clsx from 'clsx';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import AuthRequired from 'components/auth/auth-required';
import Logo from 'components/logos';
import { ReleaseNote } from 'components/release-notes/types';
import { longDateFromYYYYMMDDString } from 'components/utils';
import { LAST_VIEWED_RELEASE_NOTE_DATE } from 'components/utils/constants';
import brandConfigs from 'config/brand-resolution';
import { GetCustomerOffersVersion, GetReleaseNotes } from 'remote/queries';
import { useAuthContext } from 'state/auth';
import { EMAIL_REGEX } from 'utils';
import { routes, isRouteSelected, expandPath } from 'utils/routing';

import { useExtractPathParams } from './hooks';
import { useStyles } from './layout.styles';
import { UniversalSearch } from './universal-search';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { email, signOut, roles } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const pathParams = useExtractPathParams(location.pathname);

  const params = useMemo(() => {
    const mergedParams: Record<string, string> = { ...pathParams };

    Object.entries(routeParams).forEach(([key, value]) => {
      if (value !== undefined) {
        mergedParams[key] = value;
      }
    });

    return mergedParams;
  }, [routeParams, pathParams]);

  const customerId = params.customerId || '';
  const urlParamsIsEmail = EMAIL_REGEX.test(customerId);

  const { data: customerOffersVersionData, loading: customerOffersVersionLoading } = useQuery(
    GetCustomerOffersVersion,
    {
      variables: { customerId },
      skip: !customerId || urlParamsIsEmail,
    },
  );

  const offersVersion = customerOffersVersionData?.Customer?.offersVersion ?? 'v1';

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleReleaseNotesClick = () => {
    setReleaseNotesOpen(!releaseNotesOpen);
  };

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  };

  const handleSignOut = useCallback(async () => {
    await signOut();
    navigate(routes.signIn);
  }, [navigate, signOut]);

  const { data: releaseNotesData } = useQuery(GetReleaseNotes, {
    variables: { limit: 10, offset: 0 },
  });

  const lastViewedReleaseDate = localStorage.getItem(LAST_VIEWED_RELEASE_NOTE_DATE) ?? '';
  const unviewedReleaseNotes = !!releaseNotesData?.ReleaseNotes
    ? releaseNotesData?.ReleaseNotes.filter((note: ReleaseNote) => {
        return !!note.releaseDate && note.releaseDate > lastViewedReleaseDate;
      })
    : [];

  const numUnviewedReleaseNotes = unviewedReleaseNotes.length;

  return (
    <div className={classes.root}>
      <AuthRequired>
        <>
          <AppBar
            position="fixed"
            variant="outlined"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: drawerOpen,
                })}
              >
                <Menu />
              </IconButton>

              <Link to={routes.default} className={classes.logo}>
                <Logo />
              </Link>
              <UniversalSearch />
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>

            <Divider />

            <List>
              <ListItem button component={Link} to={routes.default}>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={'Home'} />
              </ListItem>
            </List>

            <Divider />

            <List>
              {brandConfigs.links.map(({ name, route, Icon }) => {
                const isOffersRoute = route.startsWith('/offers');
                const isOffersRouteAndCustomerOffersVersionLoading =
                  isOffersRoute && customerOffersVersionLoading;
                const offersRoute = `/offers-${offersVersion}`;

                let resolvedRoute = isOffersRouteAndCustomerOffersVersionLoading
                  ? routes.offersLD
                  : isOffersRoute
                  ? offersRoute
                  : route;

                const currentPathParts = location.pathname.split('/').filter(Boolean);
                const isCustomerRelatedPath =
                  currentPathParts.length > 1 && currentPathParts[0] === 'customer';

                const navigationParams = { ...params };

                const isOrdersRoute = route === routes.orders;

                // Navigation parameter cleanup logic:
                // 1. When switching between major sections (e.g. customer -> store), clear most params
                // 2. When switching between customer subsections (e.g. orders -> offers), preserve customerId
                // 3. When staying in orders section, preserve both customerId and orderId
                if (
                  resolvedRoute.split('/')[1] !== currentPathParts[0] ||
                  (isCustomerRelatedPath &&
                    currentPathParts.length > 2 &&
                    resolvedRoute !== `/${currentPathParts[2]}`)
                ) {
                  Object.keys(navigationParams).forEach((key) => {
                    if (key !== 'customerId' && !(key === 'orderId' && isOrdersRoute)) {
                      delete navigationParams[key];
                    }
                  });
                }

                const targetPath = expandPath(resolvedRoute, navigationParams);

                return (
                  <ListItem
                    button
                    key={name}
                    selected={isRouteSelected(route, location.pathname)}
                    component={Link as any}
                    to={targetPath}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                );
              })}
            </List>

            <Divider />

            <List>
              <ListItem button onClick={handleUserClick} disabled={!drawerOpen}>
                <ListItemIcon>
                  <Avatar className={classes.avatar}>{email?.[0]}</Avatar>
                </ListItemIcon>
                <ListItemText className={classes.email} primary={email?.split('@')?.[0]} />
                {userOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={userOpen && drawerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(roles as string[]).map((role) => {
                    return (
                      <ListItem className={classes.nested} key={role}>
                        <ListItemIcon>
                          <Language />
                        </ListItemIcon>
                        <ListItemText primary={role} />
                      </ListItem>
                    );
                  })}
                  {roles.length === 0 && (
                    <ListItem className={classes.nested}>
                      <ListItemIcon>
                        <Warning />
                      </ListItemIcon>
                      <ListItemText primary={'No roles assigned'} />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </List>

            <Divider />

            <List>
              <ListItem
                button
                onClick={handleReleaseNotesClick}
                component={Link as any}
                to={routes.releaseNotes}
                disabled={!drawerOpen}
              >
                <ListItemIcon>
                  <Badge badgeContent={numUnviewedReleaseNotes} color="primary">
                    <Note />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={'Release Notes'} />
                {!!releaseNotesData?.ReleaseNotes.length ? (
                  releaseNotesOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              <Collapse in={releaseNotesOpen && drawerOpen}>
                <List component="div" disablePadding>
                  {releaseNotesData?.ReleaseNotes?.slice(0, 3).map(
                    (note: ReleaseNote, index: number) => {
                      return (
                        <ListItem
                          button
                          component={Link as any}
                          to={`${routes.releaseNotes}/${note?.releaseDate}`}
                          className={classes.nested}
                          key={index}
                        >
                          <ListItemIcon>
                            <Today />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              !!note.releaseDate && longDateFromYYYYMMDDString(note.releaseDate)
                            }
                          />
                        </ListItem>
                      );
                    },
                  ) ?? null}
                </List>
              </Collapse>
            </List>

            <Divider />

            <List>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={'Sign Out'} />
              </ListItem>
            </List>

            <Divider />
          </Drawer>
        </>
      </AuthRequired>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Layout;
