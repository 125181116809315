import { ApolloClientProvider } from './state/apollo';
import { AuthProvider } from './state/auth';
import { CTGLoyaltyProvider } from './state/ctg-loyalty-legacy';
import { IntlProvider } from './state/intl';
import { NotificationProvider } from './state/notification';
import { ThemeProvider } from './state/theme';
import { UniversalSearchProvider } from './state/universal-search';

const providers = [
  ThemeProvider,
  IntlProvider,
  NotificationProvider,
  AuthProvider,
  ApolloClientProvider,
  CTGLoyaltyProvider,
  UniversalSearchProvider,
];

export default providers;
