import gql from 'graphql-tag';

export const ProgressFragment = gql`
  fragment ProgressFragment on MdwEngineIQuestProgress {
    steps {
      stepId
      completedAt
      transaction {
        id
      }
    }
    activatedOn
  }
`;

export const CTGLoyaltyQuests = gql`
  query ctgLoyaltyQuestsByLoyaltyId($loyaltyId: String!) {
    ctgLoyaltyQuestsByLoyaltyId(loyaltyId: $loyaltyId) {
      id
      endDate
      incentiveType
      name
      progress {
        ...ProgressFragment
      }
      completions {
        ...ProgressFragment
      }
      startDate
      steps {
        id
        minimumSpend
        cartRequirements {
          id
          quantity
          name
        }
      }
      type
      sourceType
    }
  }
  ${ProgressFragment}
`;
