import { useMemo } from 'react';

// Pure function version for testing
export const extractPathParams = (pathname: string): Record<string, string> => {
  const pathParts = pathname.split('/').filter(Boolean);
  const params: Record<string, string> = {};

  // Detect duplicate 'orders' in the path
  const ordersSegments = pathParts.filter((part) => part === 'orders');
  if (ordersSegments.length > 1) {
    console.error(`Duplicate "orders" segments detected in path: ${pathname}`);
  }

  // Extract parameters based on route patterns
  if (pathParts.length > 0) {
    switch (pathParts[0]) {
      case 'customer':
        if (pathParts.length > 1) {
          params.customerId = pathParts[1];

          // Extract orderId if it exists
          if (pathParts.length > 3 && pathParts[2] === 'orders') {
            params.orderId = pathParts[3];
          }

          // Extract transactionId if it exists
          if (pathParts.length > 3 && pathParts[2] === 'ctg-loyalty-transaction') {
            params.transactionId = pathParts[3];
          }
        }
        break;
      case 'store':
        if (pathParts.length > 1) {
          params.storeId = pathParts[1];
        }
        break;
      case 'release-notes':
        if (pathParts.length > 1) {
          params.date = pathParts[1];
        }
        break;
    }
  }

  return params;
};

// Hook that uses the pure function
export const useExtractPathParams = (pathname: string) => {
  return useMemo(() => extractPathParams(pathname), [pathname]);
};
