import { ComponentType, Suspense, lazy, LazyExoticComponent, FC } from 'react';

import { Route, BrowserRouter, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Layout from 'components/layout';
import Loader from 'components/loader';
import ReduceProviders from 'components/reduce-providers';
import brandConfigs from 'config/brand-resolution';
import { useAuthContext } from 'state/auth';

import providers from './providers';
import { routes } from './utils/routing';

import './index.css';

type LazyRoute = LazyExoticComponent<ComponentType<any>>;

const Challenges: LazyRoute = lazy(() => import('./containers/challenges'));
const CtgLoyalty: LazyRoute = lazy(() => import('./containers/ctg-loyalty'));
const CTGLoyaltyTransaction: LazyRoute = lazy(() => import('./containers/ctg-loyalty-transaction'));
const Customer: LazyRoute = lazy(() => import('./containers/customer'));
const GiftCard: LazyRoute = lazy(() => import('./containers/giftcard'));
const Home: LazyRoute = lazy(() => import('./containers/home'));
const OffersLD: LazyRoute = lazy(() => import('./containers/offers-ld'));
const OffersV1: LazyRoute = lazy(() => import('./containers/offers-unified'));
const OffersV2: LazyRoute = lazy(() => import('./containers/offers-unified'));
const Order: LazyRoute = lazy(() => import('./containers/order'));
const Orders: LazyRoute = lazy(() => import('./containers/orders'));
const ReleaseNotes: LazyRoute = lazy(() => import('./containers/release-notes'));
const SignIn: LazyRoute = lazy(() => import('./containers/sign-in'));
const StoreDetails: LazyRoute = lazy(() => import('./containers/store'));
const SupportHistory: LazyRoute = lazy(() => import('./containers/support-history'));

const NotFound: FC = () => <p>Sorry, nothing here</p>;

const SuspenseWrapper = ({ component: Component }: { component: React.ComponentType<any> }) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
);

const ContentRouting = () => {
  const { ctgLoyaltyEnabled } = brandConfigs;
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated()) {
    return (
      <Suspense fallback={<Loader />}>
        <SignIn />
      </Suspense>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route element={<SuspenseWrapper component={Home} />} path={routes.default} />

        <Route element={<SuspenseWrapper component={Customer} />} path={routes.customer} />
        <Route
          element={<SuspenseWrapper component={Customer} />}
          path={`${routes.customer}/:customerId`}
        />

        <Route element={<SuspenseWrapper component={Orders} />} path={routes.orders} />
        <Route
          element={<SuspenseWrapper component={Orders} />}
          path={`${routes.customer}/:customerId${routes.orders}`}
        />
        <Route
          element={<SuspenseWrapper component={Order} />}
          path={`${routes.customer}/:customerId${routes.orders}/:orderId`}
        />

        <Route element={<SuspenseWrapper component={GiftCard} />} path={routes.giftCard} />
        <Route
          element={<SuspenseWrapper component={GiftCard} />}
          path={`${routes.giftCard}/:isoCountryCode/:cardNumber`}
        />
        <Route
          element={<SuspenseWrapper component={GiftCard} />}
          path={`${routes.customer}/:customerId${routes.giftCard}`}
        />

        {ctgLoyaltyEnabled && (
          <>
            <Route
              element={<SuspenseWrapper component={OffersLD} />}
              path={routes.offersLD}
              key={routes.offersLD}
            />
            <Route
              element={<SuspenseWrapper component={OffersLD} />}
              path={`${routes.customer}/:customerId${routes.offersLD}`}
            />
            <Route
              element={<SuspenseWrapper component={OffersV1} />}
              path={routes.offersV1}
              key={routes.offersV1}
            />
            <Route
              element={<SuspenseWrapper component={OffersV1} />}
              path={`${routes.customer}/:customerId${routes.offersV1}`}
            />
            <Route
              element={<SuspenseWrapper component={OffersV2} />}
              path={routes.offersV2}
              key={routes.offersV2}
            />
            <Route
              element={<SuspenseWrapper component={OffersV2} />}
              path={`${routes.customer}/:customerId${routes.offersV2}`}
            />
            <Route element={<SuspenseWrapper component={CtgLoyalty} />} path={routes.ctgLoyalty} />
            <Route
              element={<SuspenseWrapper component={CtgLoyalty} />}
              path={`${routes.customer}/:customerId${routes.ctgLoyalty}`}
            />
            <Route
              element={<SuspenseWrapper component={CTGLoyaltyTransaction} />}
              path={`${routes.customer}/:customerId${routes.ctgLoyaltyTransaction}/:transactionId`}
            />
            <Route
              element={<SuspenseWrapper component={Challenges} />}
              path={routes.challenges}
              key={routes.challenges}
            />
            <Route
              element={<SuspenseWrapper component={Challenges} />}
              path={`${routes.customer}/:customerId${routes.challenges}`}
            />
          </>
        )}

        <Route element={<SuspenseWrapper component={StoreDetails} />} path={routes.store} />
        <Route
          element={<SuspenseWrapper component={StoreDetails} />}
          path={`${routes.store}/:storeId`}
        />

        <Route element={<SuspenseWrapper component={ReleaseNotes} />} path={routes.releaseNotes} />
        <Route
          element={<SuspenseWrapper component={ReleaseNotes} />}
          path={`${routes.releaseNotes}/:date`}
        />

        <Route
          element={<SuspenseWrapper component={SupportHistory} />}
          path={routes.supportHistory}
        />
        <Route
          element={<SuspenseWrapper component={SupportHistory} />}
          path={`${routes.customer}/:customerId${routes.supportHistory}`}
        />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Layout>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <ReduceProviders providers={providers}>
        <CssBaseline />
        <ContentRouting />
      </ReduceProviders>
    </BrowserRouter>
  );
};

export default App;
