import { FC } from 'react';
import { ReactNode } from 'react';

import useAuthRedirects from 'hooks/use-auth-redirect';
import { useAuthContext } from 'state/auth';

interface AuthRequiredProps {
  children: ReactNode;
}

const AuthRequired: FC<AuthRequiredProps> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  useAuthRedirects();

  return isAuthenticated() ? <>{children}</> : null;
};

export default AuthRequired;
