import React, { FC, createContext, useContext } from 'react';

import useCTGLoyalty, { ICTGLoyaltyContext } from 'hooks/use-ctg-loyalty';
import { IsoCountryCode2Char } from 'types';

const DEFAULT_VALUE: ICTGLoyaltyContext = {
  loyaltyMarket: IsoCountryCode2Char.US,
  setLoyaltyMarket: () => {},
  loading: false,
  error: '',
  issuePoints: () => Promise.resolve(undefined),
  fetchLoyaltyUserByEmail: () => Promise.resolve(undefined),
  fetchLoyaltyUserById: () => Promise.resolve(undefined),
  fetchLoyaltyTransactions: () => Promise.resolve([]),
};

export const CTGLoyaltyContext = createContext(DEFAULT_VALUE);
export const useCTGLoyaltyContext = () => useContext(CTGLoyaltyContext);

interface CTGLoyaltyProviderProps {
  children: React.ReactNode;
}

export const CTGLoyaltyProvider: FC<CTGLoyaltyProviderProps> = ({ children }) => {
  const data = useCTGLoyalty();
  return <CTGLoyaltyContext.Provider value={data}>{children}</CTGLoyaltyContext.Provider>;
};

export default CTGLoyaltyContext.Consumer;
