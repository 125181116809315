import { ChangeEvent, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import {
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  List,
} from '@material-ui/core';
import { CheckCircleOutline, Clear, ErrorOutline, Search } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

import { useUniversalSearch } from 'hooks/use-universal-search';
import { UseUniversalSearchContext } from 'state/universal-search';
import { routes } from 'utils/routing';

import { SearchError } from './search-error';
import { SearchInstructions } from './search-instructions';
import { SearchItem } from './search-item';
import { useStyles } from './styles';

export const UniversalSearch = () => {
  const classes = useStyles();

  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [debouncing, setDebouncing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { loading: universalLookupLoading, hits: searchResults } =
    useUniversalSearch(debouncedSearchTerm);

  const { focused } = UseUniversalSearchContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (focused === true) {
      inputRef?.current?.focus();
    } else {
      inputRef?.current?.blur();
    }
  }, [focused]);

  const storesById = searchResults.filter((result) => {
    if (
      result.__typename === 'Store' &&
      'storeNumber' in result &&
      result.storeNumber === searchTerm
    ) {
      return true;
    }
    return false;
  });

  const storesByAddress =
    searchResults[0] && searchResults[0].__typename === 'Store'
      ? storesById.length > 0
        ? searchResults.filter((result) => {
            return 'storeNumber' in result && result.storeNumber === searchTerm ? false : true;
          })
        : searchResults
      : null;

  const debounce = (func: { (value: SetStateAction<string>): void; apply?: any }) => {
    let timer: NodeJS.Timeout | null;
    return (...args: unknown[]) => {
      const context = this;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
        setDebouncing(false);
      }, 500);
    };
  };

  const handleDebouncedSearch = (value: SetStateAction<string>) => {
    setDebouncedSearchTerm(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedSearch = useCallback(debounce(handleDebouncedSearch), []);

  const isSearchFinished = !!searchTerm && !universalLookupLoading && !debouncing;
  const isSearchSuccessful = isSearchFinished && searchResults.length > 0;
  const doesSearchHaveError = isSearchFinished && searchResults.length === 0;
  const showSearchInstructions = isSearchBarFocused && !searchTerm;

  const searchIcon = isSearchSuccessful ? (
    <CheckCircleOutline data-testid="search-successful-icon" />
  ) : doesSearchHaveError ? (
    <ErrorOutline />
  ) : universalLookupLoading || debouncing ? (
    <CircularProgress size={20} color="inherit" />
  ) : (
    <Search />
  );

  const showSearchResultsList =
    searchResults.length > 0 || doesSearchHaveError || showSearchInstructions;

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setDebouncing(true);
    optimizedSearch(event.target.value);
    setSearchTerm(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    setDebouncedSearchTerm('');
    navigate(routes.default);
  };

  const handleSearchResultSelection = (targetUrl: string) => {
    setSearchTerm('');
    setDebouncedSearchTerm('');
    navigate(encodeURI(targetUrl));
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>{searchIcon}</div>
      <InputBase
        name="search"
        value={searchTerm}
        placeholder={isSearchBarFocused ? 'Search By...' : 'Search'}
        inputProps={{ 'aria-label': 'search' }}
        inputRef={inputRef}
        autoComplete="off"
        spellCheck="false"
        onChange={handleSearchInput}
        onFocus={() => setIsSearchBarFocused(true)}
        onBlur={() => setIsSearchBarFocused(false)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="clear" size="small" color="inherit" onClick={handleSearchClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        }
      />

      {showSearchResultsList && (
        <List className={classes.searchResults}>
          {showSearchInstructions && <SearchInstructions />}
          {doesSearchHaveError && (
            <SearchError
              inputRef={inputRef}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          )}
          {storesById.length > 0 && (
            <span className={classes.searchLabel}>Store Number Matches</span>
          )}
          {storesById.length > 0 &&
            storesById.map((result) => {
              return (
                <SearchItem item={result} key={result.id} onClick={handleSearchResultSelection} />
              );
            })}
          {storesById.length > 0 && !!storesByAddress && <Divider />}
          {!!storesByAddress && storesByAddress.length > 0 && (
            <span className={classes.searchLabel}>Store Address Matches</span>
          )}
          {!!storesByAddress
            ? storesByAddress.map((result) => {
                return (
                  <SearchItem item={result} key={result.id} onClick={handleSearchResultSelection} />
                );
              })
            : searchResults.map((result) => {
                return (
                  <SearchItem item={result} key={result.id} onClick={handleSearchResultSelection} />
                );
              })}
        </List>
      )}
    </div>
  );
};
