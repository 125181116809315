import { useState, useEffect, ReactNode } from 'react';

import { Tooltip, IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import { EMPTYDATA } from 'components/utils/constants';

import { useStyles } from './copyable-text.styles';

const CopyableText = ({ annotation, text }: { annotation: string; text?: ReactNode }) => {
  const classes = useStyles();
  const [justClickedCopy, setJustClickedCopy] = useState(false);

  useEffect(() => {
    if (justClickedCopy) {
      const timeout = setTimeout(() => setJustClickedCopy(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [justClickedCopy]);

  if (typeof text === 'string') {
    return (
      <div className={classes.wrapper}>
        <span className={classes.text}>{text}</span>
        <span className={classes.buttonWrapper}>
          <CopyToClipboard text={text}>
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={() => setJustClickedCopy(true)}
            >
              <Tooltip
                title={justClickedCopy ? `Copied to Clipboard!` : `Copy ${annotation}`}
                arrow
              >
                <FileCopy className={classes.icon} />
              </Tooltip>
            </IconButton>
          </CopyToClipboard>
        </span>
      </div>
    );
  }
  return <>{EMPTYDATA}</>;
};

export default CopyableText;
