import { ENVIRONMENT } from '../../constants';

export const routes = {
  customer: '/customer',
  default: '/',
  giftCard: '/giftcard',
  loyaltyLegacy: '/loyalty-legacy',
  ctgLoyaltyTransaction: '/ctg-loyalty-transaction',
  ctgLoyalty: '/ctg-loyalty',
  challenges: '/challenges',
  offersLD: '/offers-ld',
  offersV1: '/offers-v1',
  offersV2: '/offers-v2',
  orders: '/orders',
  releaseNotes: '/release-notes',
  history: '/history',
  signIn: '/signin',
  store: '/store',
  supportHistory: '/support-history',
};

export const nestedResources = {
  [routes.customer]: [
    routes.giftCard,
    routes.ctgLoyalty,
    routes.offersLD,
    routes.offersV1,
    routes.offersV2,
    routes.orders,
    routes.supportHistory,
    routes.challenges,
  ].map((resource) => resource.replace('/', '')),
};

export const isRouteSelected = (route: string, pathName: string) => {
  // This function is not an exact science
  // One day we will migrate to React Router and use https://v5.reactrouter.com/web/api/matchPath
  // Until the this appears to be the best we can do...
  const routeResource = route.replace('/', '');
  const pathParts = pathName.split('/').filter(Boolean);
  const firstPathPart = `/${pathParts[0]}`;
  const lastPathPart = pathParts.slice(-1)[0];
  const isCurrentPathNestedResource = !!nestedResources[firstPathPart]?.includes(lastPathPart);

  if (isCurrentPathNestedResource) {
    // we want the offers tab to remain selected whether the agent is viewing offers v1 or v2
    if (lastPathPart.startsWith('offers')) {
      return routeResource.includes('offers');
    }
    // all other nested resources
    return lastPathPart === routeResource;
  }
  if (pathParts.length === 4) {
    // special case for double nested orders route: customers/:customerId/orders/:orderId
    return pathParts[2] === routeResource;
  }
  if (route === routes.giftCard) {
    return firstPathPart === route || lastPathPart === routeResource;
  }
  if (pathParts.length === 1 || pathParts.length === 2) {
    return firstPathPart === route;
  }
};

export const expandPath = (resource: string, params: Record<string, string>) => {
  const { customerId = '', storeId = '', orderId = '' } = params;

  const normalizePath = (basePath: string, resourcePath: string) => {
    const normalizedResource = resourcePath.replace(/^\//, '');
    return `${basePath}/${normalizedResource}`;
  };

  if (customerId) {
    const customerBasePath = `${routes.customer}/${customerId}`;

    if (resource === routes.customer) {
      return customerBasePath;
    }

    if (resource === routes.orders) {
      const ordersPath = normalizePath(customerBasePath, routes.orders);
      return orderId ? `${ordersPath}/${orderId}` : ordersPath;
    }

    if (
      [
        routes.ctgLoyalty,
        routes.offersLD,
        routes.offersV1,
        routes.offersV2,
        routes.giftCard,
        routes.supportHistory,
        routes.challenges,
      ].includes(resource)
    ) {
      return normalizePath(customerBasePath, resource);
    }
  }

  if (resource === routes.store && storeId) {
    return `${routes.store}/${storeId}`;
  }

  return resource;
};

export const buildDynamoDbPath = (orderId: string) => {
  return `https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#edit-item?itemMode=2&pk=${orderId}&route=ROUTE_ITEM_EXPLORER&sk=current%2Forder&table=aws-rbi-${ENVIRONMENT}-bk`;
};

export const buildDatadogPath = (query: (string | undefined)[], orderCreatedAt: string) => {
  const timestamp = new Date(orderCreatedAt).getTime();

  const fiveMinutesBeforeCreatedAt = timestamp - 5 * 60 * 1000; // Subtract 5 minutes
  const oneHourAfterCreatedAt = timestamp + 60 * 60 * 1000; // Add 1 hour
  const queryUrl = query.filter(Boolean).join('%20OR%20');

  return `https://app.datadoghq.com/logs?query=${queryUrl}&agg_m=count&agg_m_source=base&agg_t=count&cols=service,%40msg,%40storeId,%40rbiOrderId,%40sqsRecord.messageAttributes.orderId.stringValue,%40customer.name,%40partnerOrderId&fromUser=true&messageDisplay=inline&refresh_mode=paused&storage=hot&stream_sort=desc&viz=stream&from_ts=${fiveMinutesBeforeCreatedAt}&to_ts=${oneHourAfterCreatedAt}&live=false`;
};
